/* CheckoutSummary.css */
.checkout-summary {
    display: flex;
    flex-direction: column;
  }
  
  .address-container {
    display: flex;
    flex-direction: column;
  }
  
  .address-section {
    flex: 1;
    margin: 10px;
  }
  
  @media (min-width: 768px) {
    .address-container {
      flex-direction: row;
      align-items: flex-start;
    }
  
    .address-section {
      margin-top: 0;
    }
  }