.custom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 768px) {
    .custom-container {
        flex-direction: row;
        justify-content: space-between;
    }
}

.button-container {
    display: flex;
    justify-content: flex-start;
  }
  
  @media (max-width: 767px) {
    .button-container {
      flex-direction: column;
    }
  }

.item {
    margin: 20px;
}

@media (min-width: 768px) {
    .item {
        width: 30%;
    }
}