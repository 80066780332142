.product-details-container {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.product-detail-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 10px;
}

.product-imagesc {
	width: 100px;
	height: 100px;
	object-fit: cover;
}

@media (min-width: 768px) {
	.product-details-container {
		display: grid;
		grid-template-columns: repeat(5, 1fr); /* Adjust the number of columns as needed */
		gap: 40px;
        margin-bottom: 40px;
        align-items: start;
	}

	.product-detail-item {
		display: contents; /* Allow grid items to span multiple columns */
	}

	.product-detail-item > * {
		grid-column: span 1; /* Each child spans one column */
	}

	.product-detail-item img {
		grid-column: span 1;
	}

	.product-detail-item p {
		grid-column: span 1;
	}

	.product-detail-item .product-quantity,
	.product-detail-item .product-delete {
		grid-column: span 1;
	}
}