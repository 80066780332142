.product-container {
    display: flex;
    margin: 20px;
    margin-bottom: 30px;
}

.product-image {
    flex: 3;
}

.product-details {
    flex: 4;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.product-details-text {
    text-Align: 'right';
    justify-Content: 'flex-end';
}

@media (max-width: 767px) {
    .product-container {
        flex-direction: column;
    }

    .product-image {
        flex: 1;
        width: 100%; /* subtract the left and right margin */
    }

    .product-details-text {
        text-Align: 'left';
        justify-Content: 'flex-start'
    }

    .product-image img {
        width: 100%;
        height: auto;
    }
}